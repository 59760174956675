import { useSolr } from '../../hooks/useSolr';
import MandalaSkeleton from '../common/MandalaSkeleton';
import React, { useEffect, useState } from 'react';
import TreeLeaf from './TreeLeaf';

/**
 * LeafChildren is a component under a leaf that contains the children for that node.
 * When the parent leaf node is opened, this component makes a SOLR call for all its children at once
 * to minimize the number of SOLR calls. The results are processed and added to the hierachy
 * by the KmapsTree class which is pointed to by each node with its node.tree property.
 * The KmapsTree class has a parseData function that processes any results placing children under parents by ID.
 * All calls facet on the persepective-based ancestor id and this is processed first to determine which leaf nodes
 * have children (i.e. facet value > 1 since every leaf is in its own path). A list of nodes with children is
 * compiled in the tree object and determines whether each leaf is openable or not.
 *
 *
 * @param settings
 * @param children
 * @param level
 * @param isOpen
 * @returns {JSX.Element}
 * @constructor
 */
export function LeafChildren({ node, selPath, ...props }) {
    const tree = node.tree;
    const settings = tree.settings;
    const domain = tree.domain;
    const perspective = tree.perspective;
    const doc = node?.doc;
    const level = node?.level;
    const isTerms = domain === 'terms';

    const rows = 300; // was isTerms ? 300 : 100 and before that 1000:0
    /*const qval = isTerms
        ? `${settings.level_field}:${level * 1 + 1}`
        : `${settings.level_field}:${level * 1 + 2}`;

     */
    const child_level = level * 1 + 1;
    const qval = '*:*';
    //node.sortChildren();

    const [nodeChildren, setNodeChildren] = useState([]);

    // Find children and facets of grandchildren
    let childquery = {
        index: 'terms',
        params: {
            q: qval,
            fq: [`tree:${node.domain}`, `${node.ancestor_field}:${node.kid}`],
            rows: rows,
            fl: '*',
            facet: true,
            'facet.field': node?.ancestor_field,
            'facet.mincount': 1,
            sort: `${tree?.level_field} ASC`,
        },
    };

    const toFilter = settings?.domain !== 'terms';
    // console.log('Leaf children query in LeafChildren', childquery);
    const now = Date.now();

    let {
        isLoading: areKidsLoading,
        data: kids,
        isError: isKidError,
        error: kidError,
    } = useSolr(
        [domain, perspective, doc.uid, 'children_full'],
        childquery,
        false,
        toFilter
    );

    useEffect(() => {
        // console.log("child query results in leafchildren", kids, node);
        if (!areKidsLoading && kids?.docs?.length > 0) {
            //console.log('Current level', child_level);
            const childdocs = kids?.docs?.filter(
                (kd) => kd[tree.level_field] === child_level
            );
            let facets = null;
            if (kids?.facets && tree?.ancestor_field?.length > 0) {
                const ancfield = tree.ancestor_field[0];
                if (
                    Object.keys(kids.facets).includes(ancfield) &&
                    Object.keys(kids.facets[ancfield])?.length > 0
                ) {
                    facets = kids.facets[ancfield];
                }
            }
            //console.log('Filtered children', childdocs);
            tree.parseData(childdocs, facets); // kids.docs
            setNodeChildren(node.getChildren());
        }
    }, [kids]);
    /*
    useEffect(() => {
        console.log('node children', nodeChildren);
    }, [nodeChildren]);
 */
    /*
    if (areKidsLoading) {
        return <MandalaSkeleton />;
    }*/
    /*
    if (!areKidsLoading && node.kid === 13740) {
        console.log('child results', kids);
    }*/
    if (isKidError) {
        console.log("can't load children", kidError);
        return <p>(Something went wrong)</p>;
    }
    /*
    // WithChild if node is identified in facets more than once, it is in a path but children are not loaded.
    let withChild =
        !isTerms && kids?.numFound > 0
            ? Object.keys(kids.facets[node?.ancestor_field])
            : [];
    withChild = withChild.map((ch, ci) => {
        return ch * 1;
    });
    const isTermsLvl2 =
        isTerms && level * 1 === 1 && perspective.includes('tib');
*/
    /*
    let childrenElement = node?.hasChildren ? <MandalaSkeleton /> : null;
    if (nodeChildren.length > 0) {
        childrenElement = (
            <>
                {nodeChildren.map((child, i) => {
                    let io = false;
                    // Open automatically if in environment variable
                    if (
                        process.env?.REACT_APP_KMAP_OPEN?.split(',')?.includes(
                            child?.id
                        )
                    ) {
                        io = true;
                    }
                    return (
                        <TreeLeaf
                            key={`tree-leaf-${child.uid}`}
                            node={child}
                            withChild={child?.hasChildren}
                            isOpen={io}
                            selPath={selPath}
                        />
                    );
                })}
            </>
        );
    }*/

    return (
        <div className={settings.childrenClass}>
            {nodeChildren.length > 0 && (
                <>
                    {nodeChildren.map((child, i) => {
                        let io = false;
                        // Open automatically if in environment variable
                        if (
                            process.env?.REACT_APP_KMAP_OPEN?.split(
                                ','
                            )?.includes(child?.id)
                        ) {
                            io = true;
                        }
                        return (
                            <TreeLeaf
                                key={`tree-leaf-${child.uid}`}
                                node={child}
                                withChild={child?.hasChildren}
                                isOpen={io}
                                selPath={selPath}
                            />
                        );
                    })}
                </>
            )}
        </div>
    );
}

import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import * as PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAdvancedSearch } from '../hooks/useAdvancedSearch';
import {
    useQueryParams,
    StringParam,
    withDefault,
    encodeQueryParams,
} from 'use-query-params';
import { stringify } from 'query-string';
import { ArrayOfObjectsParam } from '../hooks/utils';
import { Cookies } from 'react-cookie';
import {
    AND,
    ANY,
    ASSET_TYPES,
    CONTAINS,
    EXACTLY,
    FIRST,
    RESOURCE_TYPE,
} from '../views/AdvancedSearch/SearchConstants';
import { SearchBuilder } from '../views/AdvancedSearch/SearchBuilder';
import './WpPageSearch.scss';
import { FormRowTemplate } from '../views/AdvancedSearch/FormRowTemplate';
import { getResultView } from '../views/AdvancedSearch/AdvancedSearchForm';

const target = document.getElementById('wpPageSearch');

const SEARCH_PATH = '/search/:view';

/**
 * A search component only displayed in portals, i.e., divs in page with id='wpPageSearch'
 * This is a copy of BasicSearch.js with modifications to make it only a portal,
 * And the search call hard-coded here, as well as adding the asset type filter dropdown.
 *
 * The portal div in the WP page must have an id of "wpPageSearch" and can have an
 * optional data-filter attribute to indicate the desired asset type selection.
 * If no data-filter attribute is given, defaults to "all"
 *
 * Example from the Sources landing page:
 *
 *      <div id="wpPageSearch" data-filter="sources"></div>
 *
 * @param props
 * @returns {React.ReactPortal|JSX.Element}
 * @constructor
 */
export function WpPageSearch(props) {
    const history = useHistory();
    const inputEl = useRef(null);
    const updateStoredRows = useAdvancedSearch((state) => state.updateRows);
    /*
    const cookie = new Cookies();
    let searchview = cookie?.get('searchview');
    if (!searchview || searchview.length == 0) {
        searchview = process.env?.REACT_APP_SEARCH_VIEW_DEFAULT || 'list';
        cookie.set('searchview', searchview);
    }

    // This tells us whether we are viewing the search results
    // so that we can give a link to go there (or not).
    const searchView = useRouteMatch(SEARCH_PATH);
    */
    const [query, setQuery] = useQueryParams({
        searchText: StringParam,
        filters: withDefault(ArrayOfObjectsParam, []),
    });
    let { searchText: search, filters } = query;

    const handleSubmit = () => {
        // Get the input value in the search text box
        const inputNoQuotes = inputEl?.current?.value
            ? inputEl.current.value.trim()
            : '';
        // console.log("input no quotes", `'${inputNoQuotes}'`);

        // Build Advance Search Form Row for text query
        const newrows = [
            new FormRowTemplate(0, FIRST, ANY, CONTAINS, inputNoQuotes),
        ];

        // Get value of asset type dropdown in wp in page form and add a search row for it if not "all"
        const atypeval = document.getElementById('at-select').value;
        if (atypeval && atypeval.length > 0 && atypeval !== 'all') {
            newrows.push(new FormRowTemplate(1, AND, RESOURCE_TYPE, atypeval));
        }
        // Create the query for input filtered by asset type
        updateStoredRows(newrows);
        const searchBuilder = new SearchBuilder(newrows);
        const newqry = searchBuilder.buildQuery();
        let searchview = getResultView(atypeval); // set the view based on asset type

        // Open the facet filter side-column
        document.getElementById('advanced-search-tree-toggle').click();

        // Encode the Query for URL
        const encodedQuery = encodeQueryParams(
            {
                searchText: StringParam,
                filters: withDefault(ArrayOfObjectsParam, []),
            },
            {
                searchText: `advSearch:${newqry}`,
                filters: [...filters],
            }
        );

        // Execute the query by updating location or route
        if (process.env.REACT_APP_STANDALONE === 'standalone') {
            window.location.hash = `#/search/${searchview}?${stringify(
                encodedQuery
            )}`;
        } else {
            //history.push('/search/deck');
            history.push({
                pathname: `/search/` + searchview,
                search: `?${stringify(encodedQuery)}`,
            });
        }
    };

    const clearInput = () => {
        inputEl.current.value = '';
        setQuery(
            {
                searchText: '',
                filters,
            },
            'push'
        );
    };

    const handleKey = (x) => {
        // submit on return
        if (x.keyCode === 13) {
            handleSubmit();
        }
    };

    function cleanSearch(srch) {
        let newSearch = '';
        if (!srch.startsWith('advSearch')) {
            newSearch = srch;
        }
        return newSearch;
    }
    // console.log("search in basic search", search);

    const searchVal = decodeURIComponent(
        cleanSearch(encodeURIComponent(search || ''))
    );

    let atypes = {
        all: 'All',
        ...ASSET_TYPES,
    };
    const defval = target?.dataset?.filter || props?.filter || 'all';

    const wpPageSearchPortal = (
        <div className="sui-search1">
            <AssetTypeSelect atypes={atypes} defval={defval} />
            <input
                key={search}
                type="text"
                id="sui-search"
                className="sui-search2"
                defaultValue={searchVal}
                placeholder="Search &amp; Explore!"
                onKeyDownCapture={handleKey}
                ref={inputEl}
            />

            <span id="sui-clear" className="sui-search3" onClick={clearInput}>
                {' '}
            </span>
            <span
                id="sui-searchgo"
                className="sui-search4"
                onClick={handleSubmit}
            >
                <span className={'icon shanticon-magnify'}></span>
            </span>
        </div>
    );

    if (target) {
        return ReactDOM.createPortal(wpPageSearchPortal, target);
    } else {
        return null; // Don't add unless there is a target in the WP page
    }
}

function AssetTypeSelect({ defval, atypes }) {
    const keyvals = {};
    for (const [atk, atv] of Object.entries(atypes)) {
        let newval = atv;
        if (atk === 'texts') {
            newval = 'Modern Texts';
        } else if (atk === 'sources') {
            newval = 'Bibliographies';
        }
        keyvals[atk] = newval;
    }
    let keysorted = Object.keys(keyvals);
    keysorted.sort((a, b) => {
        if (keyvals[a] > keyvals[b]) {
            return 1;
        }
        if (keyvals[b] > keyvals[a]) {
            return -1;
        }
        return 0;
    });
    return (
        <select id="at-select" name="asset_type" defaultValue={defval}>
            {keysorted.map((ky, ki) => {
                return (
                    <option value={ky} key={`at-choice-${ki}`}>
                        {keyvals[ky]}
                    </option>
                );
            })}
        </select>
    );
}

WpPageSearch.propTypes = { onChange: PropTypes.func };

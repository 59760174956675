import React from 'react';
import './translation.scss';

export default function PhoneticsConverter(props) {
    return (
        <div id="phontool" className="container">
            <h1>Tibetan to Phonetic Conversion Tool</h1>
            <iframe id="lbowFrame" src="/cgi-bin/lbow/phonetics.pl?sep_join=%20"  width="100%" height="100%" frameBorder="0"></iframe>
        </div>
    );
}

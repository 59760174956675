import React, { useEffect, useState } from 'react';
import { usePerspective } from '../../hooks/usePerspective';
import { PerspectiveChooser } from './KmapPerspectives';
import './KmapTree.scss';
import { useSolr } from '../../hooks/useSolr';
import MandalaSkeleton from '../common/MandalaSkeleton';
import { KTree, TreeNode } from './KmapsTree.class';
import TreeLeaf from './TreeLeaf';
import { useRouteMatch } from 'react-router-dom';

export default function KmapTree(props) {
    let settings = {
        domain: false, // Default domain is places
        kid: 0, // Only used if "level" is false
        level: false, // When level is set to a number, it shows all nodes on that level (subjects and terms)
        treeClass: 'c-kmaptree',
        leafClass: 'c-kmapleaf',
        spanClass: 'c-kmapnode',
        iconClass: 'toggle-icon',
        headerClass: 'label',
        childrenClass: 'children',
        perspective: '',
        isOpen: false,
        showAncestors: false,
        showNode: false,
        showRelatedPlaces: false,
        elid: 'kmap-tree-',
        pgsize: 200,
        project_ids: false,
        noRootLinks: false,
        selectedNode: 0, // Kmap ID number of selected node (without domain)
        selectedDomain: '',
        selPath: [],
        selData: {},
    };
    settings = { ...settings, ...props }; // Merge default settings with instance settings giving preference to latter
    const match = useRouteMatch([
        '/:baseType/:id/related-:type/:definitionID/view/:relID',
        '/:baseType/:id/related-:type/:definitionID/:viewMode',
        '/:baseType/:id/related-:type',
        '/:baseType/:id',
    ]);
    const [selPath, setSelPath] = useState([]); // Update for MANU-7590. Get kmap on each path change and send selpath to all nodes if in the right domain
    const selData = props?.selData;

    settings.selectedNode = match ? match.params.id : 0;
    settings.selectedDomain = match ? match.params.baseType : '';
    if (!!this?.showNode) {
        settings.selectedNode = this.showNode;
        settings.selectedDomain = this.domain;
    }

    settings.elid += `${settings.domain}:${settings.perspective}`;
    const perspective = usePerspective((state) => state[settings.domain]);
    const setPerspective = usePerspective((state) => state.setPerspective);
    settings.perspective = perspective;
    // console.log("perspective", perspective);
    settings.ancestor_field = [
        `ancestor_ids_${settings.perspective}`,
        'ancestor_ids_generic',
    ]; // this works for Places, TODO: check for subjects and terms

    settings.level_field = `level_${settings.perspective}_i`;
    settings.sort_field = `header`; // places and subjects
    if (settings.domain === 'terms') {
        settings.sort_field = 'position_i';
    }
    settings.treeClass += ` ${settings.domain} ${settings.perspective?.replace(
        /\./g,
        '-'
    )}`;

    // Set root information for this tree so they can be passed to each leaf
    settings['root'] = {
        domain: settings?.domain,
        kid: settings?.kid,
        level: settings?.level,
        perspective: perspective,
    };
    settings.debug = settings?.elid?.includes('related-places-tree');

    const [ktree, setKtree] = useState(null);

    let selkid = null;
    const toFilter = settings?.domain !== 'terms'; // Perspective does the filtering for terms.

    const treebasequery = {
        index: 'terms',
        params: {
            q: `${settings.level_field}:[1 TO 2]`,
            fq: `tree:${settings.domain}`,
            rows: 200,
            fl: '*',
            facet: true,
            'facet.field': settings.ancestor_field,
            sort: `${settings.level_field} ASC`,
        },
    };

    const {
        isLoading: isTreeLoading,
        data: treeData,
        isError: isTreeError,
        error: treeError,
    } = useSolr(
        ['tree', settings?.domain, settings?.perspective, selkid],
        treebasequery,
        false,
        toFilter
    );

    useEffect(() => {
        // When tree data changes, load the tree class (which is asynchronous, it loads selected node if there is one)
        if (treeData?.docs?.length > 0) {
            let facets = false;
            // Use children facets for the first ancestory field. TODO: check this is ok.
            // console.log('treedata with docs', treeData);
            if (
                treeData?.facets &&
                treeData?.facets[settings.ancestor_field[0]]
            ) {
                facets = treeData.facets[settings.ancestor_field[0]];
                // console.log("facets", facets);
            }
            const childdocs = treeData.docs.filter(
                (dc) => dc[settings.level_field] === 1
            );
            let nkt = new KTree(
                settings.domain,
                settings.perspective,
                childdocs,
                facets,
                settings
            );

            setTimeout(() => {
                // When it is loaded, i.e. call for selected node has returned and been processed, then....
                if (nkt?.selLoaded) {
                    setKtree(nkt); // Set the tree in state to refresh page
                }
            }, 1000);
        }
        return () => {};
    }, [treeData]);

    useEffect(() => {
        if (selData && selData?.tree === settings.domain) {
            // If selData changes, then update the selPath state which will cascade down leaves and open new selected
            // Specific for newari perspective in terms. To switch to that perspective when loaded cold
            // TODO: See if there is a more general way to do this
            if (Object.keys(selData).includes('ancestor_ids_new.alpha')) {
                if (perspective !== 'new.alpha') {
                    setPerspective('terms', 'new.alpha');
                }
                setSelPath(selData['ancestor_ids_new.alpha']);
            } else if (selData[`ancestor_id_${settings.perspective}_path`]) {
                // If selected item is not in tree, use perspective path as tree is defined by perspective
                const selpath =
                    selData[`ancestor_id_${settings.perspective}_path`].split(
                        '/'
                    );
                setSelPath(selpath.map((pn) => pn * 1));
            } else if (settings?.ancestor_field?.length > 0) {
                // otherwise use the path contained in the first of ancestor fields that has one
                // (these are usually perspective ancestory path and generic ancestor path)
                for (let afn = 0; afn < settings.ancestor_field.length; afn++) {
                    let fn = settings.ancestor_field[afn];
                    if (Object.keys(selData).includes(fn)) {
                        setSelPath(selData[fn]);
                        break;
                    }
                }
            } else if (selData?.ancestor_ids_generic) {
                // default back is generic ancestors.
                setSelPath(selData.ancestor_ids_generic);
            }
        }
    }, [selData]);

    if (isTreeLoading) {
        return <MandalaSkeleton />;
    }

    // Display the Perspective Chooser and the tree
    return (
        <div id={settings.elid} className={settings.treeClass}>
            <PerspectiveChooser
                domain={settings.domain}
                current={perspective}
            />
            {!ktree?.trunk && <MandalaSkeleton />}
            {ktree?.trunk?.map((nd, ni) => {
                const tlkey = `treeleaf-${nd.uid}-${ni}`;
                const isInPath = selPath.includes(nd.kid);
                return (
                    <TreeLeaf
                        key={tlkey}
                        node={nd}
                        isOpen={settings.isOpen || isInPath}
                        selPath={selPath}
                    />
                );
            })}
        </div>
    );
}

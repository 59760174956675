import './css/CatalogFrame.scss';
import { useCatSolr } from './hooks/useCatSolr';
import THLSkeleton from './common/THLSkeleton';
import React from 'react';
import './css/CatalogFrame.scss';
import { sortBibls } from './common/catalogUtils';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { NavSearch } from './search/NavSearch';
import { CollInfo } from './CollInfo';
import { useSearchStore } from './hooks/useSearchStore';

export default function CatalogSummary() {
    // { path && (<Redirect path={path} />) }
    const location = useLocation();
    const urlparams = new URLSearchParams(location?.search);
    let path = urlparams.get('path');

    if (path && path?.length > 0) {
        if (path.charAt(0) !== '/') {
            path = '/' + path;
        }
        path = (process.env.REACT_APP_BASE_PATH + path).replace(/\/\//g, '/');
        return <Redirect to={path} />;
    }

    return (
        <div className="l-catalog-wrapper">
            <div className="summary">
                <div className="intro">
                    <h1>Catalog Overview</h1>
                    <p>The following catalogs are available:</p>
                </div>
                <div className="search-form">
                    <NavSearch />
                </div>
            </div>
            <div className="collections">
                <CollList />
            </div>
        </div>
    );
}

function CollList() {
    const query = {
        q: 'subtype:(collection OR edition)',
        fq: 'type:tibbibl',
        fl: '*',
        rows: 200,
    };
    let {
        isLoading: isCollInfoLoading,
        data: collInfo,
        isError: isCollInfoError,
        error: collInfoError,
    } = useCatSolr(query);

    if (isCollInfoLoading) {
        return <THLSkeleton />;
    }

    if (isCollInfoError) {
        return (
            <p>
                <strong>Notice:</strong> Unable to contact the SOLR index (
                {collInfoError?.message}).
            </p>
        );
    }

    if (!collInfo?.docs || collInfo.docs.length === 0) {
        return (
            <p>
                No collections found in the SOLR index. Please talk to your
                administrator.
            </p>
        );
    }
    console.log('coll info in collsummary', collInfo);
    collInfo = sortBibls(collInfo.docs, 'title_en');

    return (
        <>
            {collInfo
                ?.filter((ci) => ci.subtype === 'collection')
                .map((d, i) => {
                    return (
                        <CollInfo
                            key={`coll-list-item-${i}`}
                            doc={d}
                            eds={collInfo.filter(
                                (ci) =>
                                    ci.subtype === 'edition' &&
                                    ci.coll === d?.coll
                            )}
                        />
                    );
                })}
        </>
    );
}
